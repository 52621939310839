export const langEN = 'en';
export const langES = 'es';

export const BASIC_ROUTES = {
  about: 'about'
}

export const routeTypesByLang = {
  [BASIC_ROUTES.about]: {
    en: '/about',
    es: '/sobremi'
  }
};


export function getScreenWidth() {
  if (typeof window !== `undefined`) {
    return window.innerWidth;
  }
}

export function isWideScreen() {
  if (typeof window !== `undefined`) {
    const windowWidth = window.innerWidth;
    const mediaQueryL = 1024;

    return windowWidth >= mediaQueryL;
  }
}

export function timeoutThrottlerHandler(timeouts, name, delay, handler) {
  if (!timeouts[name]) {
    timeouts[name] = setTimeout(() => {
      timeouts[name] = null;
      handler();
    }, delay);
  }
}

export function getCurrentLang(lang) {
  try {
    if (lang && lang.indexOf(langES) > -1) return langES;
    return langEN;
  } catch (error) {
    return langEN;
  }
}

export function getRouteByLang(lang, routeType) {
  try {
    const currentLang = getCurrentLang(lang);
    const route = routeTypesByLang[routeType];

    if (route && route[currentLang]) return route[currentLang];

    return '';
  } catch (error) {
    return '';
  }
}
