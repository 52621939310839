module.exports = {
	siteTitle: 'Aviación con Paulita', // <title>
	shortSiteTitle: 'Aviación con Paulita', // <title> ending for posts and pages
	siteDescription: 'Aviación con Paulita todos los tips, noticias y mas para empezar con tu carrera de aviación',
	siteUrl: 'https://aviacionconpaulita.com',
	pathPrefix: '',
	siteImage: 'preview.png',
	siteLanguage: 'es',
	keywords: 'aviacion,piloto,la piloto,paulita,aviacion con paulita,tips aviacion',

	/* author */
	authorName: 'Paula Gomez',
	authorTwitterAccount: 'aviacionpaulita',

	/* info */
	headerTitle: 'Aviación con Paulita',
	headerSubTitle: 'Primer Oficial - Boeing 737-700|800',

	/* manifest.json */
	manifestName: 'Aviacion con Paulita',
	manifestShortName: 'Paulita', // max 12 characters
	manifestStartUrl: '/index.html',
	manifestBackgroundColor: 'white',
	manifestThemeColor: '#704FAF',
	manifestDisplay: 'standalone',
	mailchimpBase: 'https://aviacionconpaulita.us10.list-manage.com',

	mailchimpUrl:
		'https://aviacionconpaulita.us10.list-manage.com/subscribe/post?u=b06641f0f32e3986de596f5a1&amp;id=2c7057b396',

	// gravatar
	// Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
	// Replace your email adress with md5-code.
	// Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
	// gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
	gravatarImgMd5: '',

	// social
	authorSocialLinks: {
		instagram: 'https://instagram.com/aviacionconpaulita',
		twitter: 'https://twitter.com/aviacionpaulita',
		facebook: 'https://www.facebook.com/aviacionconpaulita'
	}
};
