import React from 'react';
import PropTypes from 'prop-types';
import SuscriptionForm from '../Suscription/Suscription';

import config from '../../../content/meta/config';

import FacebookIcon from '!svg-react-loader!../../images/svg-icons/i8-facebook.svg?name=FacebookLogo';
import InstagramIcon from '!svg-react-loader!../../images/svg-icons/i8-instagram.svg?name=InstagramLogo';
import TwitterIcon from '!svg-react-loader!../../images/svg-icons/i8-twitter.svg?name=TwitterLogo';

const Footer = props => {
	const { html, theme, t, i18n } = props;

	return (
		<React.Fragment>
			<div className="content-blog">
				<SuscriptionForm t={t} i18n={i18n} theme={theme} />
			</div>
			<div className="follow-me-icons">
				<a rel="noopener noreferrer" href={`${config.authorSocialLinks.twitter}`} target="_blank">
					<TwitterIcon width={35} height={35} />
				</a>
				<a rel="noopener noreferrer" href={`${config.authorSocialLinks.facebook}`} target="_blank">
					<FacebookIcon width={35} height={35} />
				</a>
				<a rel="noopener noreferrer" href={`${config.authorSocialLinks.instagram}`} target="_blank">
					<InstagramIcon width={35} height={35} />
				</a>
			</div>
			<footer className="footer" dangerouslySetInnerHTML={{ __html: html }} />

			{/* --- STYLES --- */}
			<style jsx>{`
				.content-blog {
					padding: 1em 1em;
				}
				div :global(.container-blog) {
					text-align: center;
				}
				.follow-me-icons{
					margin: 0 auto;
					text-align: center;
					margin-left: -5px;
					padding-bottom: 0.3em;
					padding-top: 0.3em;
					:global(a) {
						margin-left: 8px;
					}
				}
				.footer {
					background: ${theme.color.neutral.white};
					padding: ${theme.space.inset.default};
					padding-top: 0;
					padding-bottom: 120px;

					:global(ul) {
						list-style: none;
						text-align: center;
						padding: 0;

						:global(li) {
							color: ${theme.color.neutral.gray.g};
							font-size: ${theme.font.size.xxs};
							padding: ${theme.space.xxs} ${theme.space.s};
							position: relative;
							display: inline-block;

							&::after {
								content: "•";
								position: absolute;
								right: ${`calc(${theme.space.xs} * -1)`};
							}
							&:last-child::after {
								content: "";
							}
						}
					}
				}
				@from-width tablet {
					.content-blog {
						margin: 0 auto;
						max-width: ${theme.text.maxWidth.desktop};
					}
				}

				@from-width desktop {
					.footer {
						padding: 0 1em 1.5em;
					}
				}
			`}</style>
		</React.Fragment>
	);
};

Footer.propTypes = {
	html: PropTypes.string,
	theme: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	i18n: PropTypes.object.instagram,
};

export default Footer;
