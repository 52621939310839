/* eslint no-unused-vars: 0 */

import { navigate } from 'gatsby';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import config from '../../../content/meta/config';

const FormItem = Form.Item;
const { TextArea } = Input;
import 'antd/lib/form/style/index.css';
import 'antd/lib/input/style/index.css';
import 'antd/lib/button/style/index.css';
import { ThemeContext } from '../../layouts';

const Suscription = props => {
	const { getFieldDecorator } = props.form;

	const [loading, setLoading] = useState(false);
	const [subbmitted, setSubbmitted] = useState(false);

	const { t, i18n } = props;

	console.log({ t, i18n });


	function encode(data) {
		return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&');
	}

	function handleSubmit(e) {
		e.preventDefault();
		props.form.validateFields((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
				sendMessage(values);
			}
		});
	}

	async function sendMessage(values) {
		setLoading(true);
		const { email } = values;
		if (email) {
			const { result, msg, params } = await addToMailchimp(email);

			if (msg === 'captcha') {
				const url = `${config.mailchimpBase}/subscribe/post?u=${params.u}&id=${params.id}&EMAIL=${encodeURIComponent(
					params.EMAIL
				)}`;

				window.open(url);
			} else if (result === 'success') {
				setSubbmitted(true);
			}
			setLoading(false);
		}
		// const result = await addToMailchimp;
	}

	function handleNetworkError(e) {
		console.log('submit Error');
	}

	return (
		<React.Fragment>
			<ThemeContext.Consumer>
				{theme => (
					<div className="form">
						<p>{t('sidebar.emailFormDesc')}</p>

						{subbmitted && <p>{t('siderbar.emailDescMsg')}</p>}
						{!subbmitted && <Form layout="inline" name="contact" onSubmit={handleSubmit}>
							<FormItem className="select-container" wrapperCol={{ sm: 24 }}>
								{getFieldDecorator('email', {
									rules: [
										{
											required: true,
											whitespace: true,
											type: 'email'
										}
									]
								})(<Input name="email" placeholder={t('sidebar.email')} />)}
							</FormItem>

							<FormItem>
								<Button type="primary" htmlType="submit" loading={loading}>
									{t('sidebar.subscribe')}
								</Button>
							</FormItem>
						</Form>}

						{/* --- STYLES --- */}
						<style jsx>{`
							div :global(.ant-form-item-control-wrapper) {
								width: 100%;
							}
							div :global(.select-container) {
								width: 85%;
								margin-bottom: 1.5em;
							}

							.form {
								background: ${theme.color.neutral.gray.aa};
								text-align: center;
								padding: 0em 0em 1.5em;
							}

							.form p {
								color: ${theme.color.neutral.gray.h};
								padding: 1.5em 0.0em;
							}

							.form :global(.ant-input) {
								appearance: none;
								height: auto;
								font-size: 1.2em;
								padding: 0.5em 0.6em;
							}
							.form :global(.ant-btn-primary) {
								height: auto;
								font-size: 1.2em;
								padding: 0.5em 3em;
								background: ${theme.color.brand.primary};
								border: 1px solid ${theme.color.brand.primary};
							}
							.form :global(.ant-form-explain) {
								margin-top: 0.2em;
							}

							@from-width tablet {
								div :global(.select-container) {
									width: 55%;
									margin-bottom: 0em;
								}
							}
						`}</style>
					</div>
				)}
			</ThemeContext.Consumer>
		</React.Fragment>
	);
};

Suscription.propTypes = {
	form: PropTypes.object
};

const SuscriptionForm = Form.create({})(Suscription);

export default SuscriptionForm;
